// extracted by mini-css-extract-plugin
export var SidebarReviewRotator = "index-module--SidebarReviewRotator--o3cJ7";
export var blurb = "index-module--blurb--laYFV";
export var content = "index-module--content--1gaKy";
export var linkhover = "index-module--linkhover--INekv";
export var location = "index-module--location--t99Qb";
export var name = "index-module--name--EbhRM";
export var rating = "index-module--rating--RWhPR";
export var review = "index-module--review--vfurO";
export var screenreader = "index-module--screenreader--KW-h2";
export var star = "index-module--star--tFU-K";